import {UnitExternalState, ViewState} from './unitExternal.component-interface';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import {Backup} from './unitExternal.component.interface';
import {TableViewModel} from '@components/models/table';
import {SwitchService} from '@services/switch.service';
import {ExcelService} from '@services/excel.service';
import {UnitService} from '@services/unit.service';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-unitExternal',
    templateUrl: './unitExternal.component.html',
    styleUrls: ['./unitExternal.component.scss']
})

export class UnitExternalComponent implements OnInit {
    @ViewChild('fileInput') fileInput!: ElementRef;
    currentPage: number = 1;
    pageSize: number = 5;
    searchby: string = '';
    isReset = false;
    editIndexExternalUnit: number = null;
    excelData: any;

    unitExternalState: UnitExternalState = {
        units: [],
        units_count: 0,
        id_unit: 0
    };

    viewState: ViewState = {
        showModal: false,
        showEdit: false,
        showDelete: false,
        showEditInfo: false
    };

    backup: Backup = {
        id_unit: '',
        brand: '',
        model: '',
        noperacion: '',
        registration_plate: '',
        soat: '',
        tecnomecanica: '',
        vin: '',
        voperacion: '',
        year: ''
    };

    unitExternalModel: TableViewModel = {
        title: 'Unidades externas',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {
                title: 'Placa',
                key: 'registration_plate',
                filter: false,
                data: []
            },
            {
                title: 'Modelo (Linea)',
                key: 'year',
                filter: false,
                data: []
            },
            {
                title: 'Marca',
                key: 'brand',
                filter: false,
                data: []
            },
            {
                title: 'Número interno',
                key: 'vin',
                filter: false,
                data: []
            },
            {
                title: 'Clase de vehiculo',
                key: 'model',
                filter: false,
                data: []
            },
            {
                title: 'Número operación',
                key: 'noperacion',
                filter: false,
                data: []
            },
            {
                title: 'SOAT',
                key: 'soat',
                filter: false,
                data: []
            },
            {
                title: 'Revisión técnico mecánica',
                key: 'tecnomecanica',
                filter: false,
                data: []
            },
            {
                title: 'Tarjeta de operación',
                key: 'voperacion',
                filter: false,
                data: []
            }
        ],
        records: [],
        totalRecords: [],
        showFilters: true,
        enableActions: true,
        removeCard: true
    };

    constructor(
        private unitService: UnitService,
        private md1: SwitchService,
        private cd: ChangeDetectorRef,
        private toastr: ToastrService,
        private excelService: ExcelService
    ) {}

    ngOnInit(): void {
        this.getUnitsExternals();
    }

    getUnitsExternals(currentPage: number = this.currentPage) {
        this.unitService
            .getUnitsExternalFuecs(currentPage, this.pageSize, this.searchby)
            .then((units) => {
                if (units.result) {
                    this.unitExternalState.units_count = units.total_count;
                    this.unitExternalModel.records = units.result;
                    this.unitExternalState.units = units.result;
                    this.unitExternalModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: units.count,
                        rowCount: this.unitExternalState.units_count
                    };
                }
            });
    }

    getTotalUnitsExternals(header: any) {
        this.unitService.getUnitsExternalFuecs().then(async (units) => {
            if (units) {
                this.unitExternalModel.totalRecords = units.result;
                var data = {
                    title: header.title,
                    header: header.header,
                    data: []
                };
                for (let record of units.result) {
                    const row = header.keys.map((key) => record[key]);
                    data.data.push(row);
                }
                await this.excelService.generateExcel(data);
            }
        });
    }

    // DOWNLOAD TEMPLATE AND IMPORT DATA

    downloadTemplate() {
        const url = '/assets/templates/PlantillaVehiculosExternos.xlsx';
        const link = document.createElement('a');
        link.href = url;
        link.download = 'PlantillaVehiculosExternos.xlsx';
        link.click();
        this.toastr.success('Plantilla descargada exitosamente');
    }

    triggerFileInput(): void {
        this.fileInput.nativeElement.click();
    }

    onFileSelected(event: any): void {
        const file: File = event.target.files[0];
        const fileName = file.name;
        const ext = this.unitService.getExtension(fileName);

        if (file && ext === 'xlsx') {
            const formData = new FormData();
            formData.append('file', file);

            this.unitService
                .loadUnits(formData)
                .then((data) => {
                    if (data) {
                        if (data.success === false) {
                            const keys = {
                                registration_plate: 'Placa',
                                year: 'Modelo (Línea)',
                                brand: 'Marca',
                                vin: 'Número interno',
                                model: 'Clase de vehiculo',
                                noperacion: 'Número operación',
                                soat: 'Fecha del SOAT',
                                tecnomecanica: 'Fecha de la tecnomecánica',
                                voperacion: 'Fecha de la tarjeta de operación'
                            };

                            let errorMessages = data.errors
                                .map(
                                    (error) =>
                                        `Falta el campo ${
                                            keys[error.missing_fields[0]] ||
                                            error.missing_fields[0]
                                        } en fila ${error.row_index}`
                                )
                                .join('<br>');

                            this.toastr.error(
                                'Fallo en la carga de unidades. Revise el archivo e intente nuevamente.<br>' +
                                    errorMessages,
                                '',
                                {timeOut: 500000, enableHtml: true}
                            );

                            return;
                        }
                        this.toastr.success('Unidades creadas correctamente.');

                        this.excelData = data.data || [];
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);

                        const unitPromises = this.excelData.map((unit) => {
                            unit.soatDate = new Date(unit.soat + 'T00:00:00');
                            unit.soatDate.setFullYear(
                                unit.soatDate.getFullYear()
                            );
                            unit.soat = unit.soatDate;

                            unit.tecnomecanicaDate = new Date(
                                unit.tecnomecanica + 'T00:00:00'
                            );
                            unit.tecnomecanicaDate.setFullYear(
                                unit.tecnomecanicaDate.getFullYear()
                            );
                            unit.tecnomecanica = unit.tecnomecanicaDate;

                            unit.voDate = new Date(
                                unit.voperacion + 'T00:00:00'
                            );
                            unit.voDate.setFullYear(unit.voDate.getFullYear());
                            unit.voperacion = unit.voDate;

                            return this.unitService.createUnit(unit);
                        });

                        Promise.all(unitPromises)
                            .then((unitIds) => {
                                const counterUnits = unitIds.filter(
                                    (id) => id
                                ).length;
                                if (counterUnits >= 1) {
                                    this.toastr.success(
                                        'Unidades creadas correctamente.'
                                    );
                                    this.getUnitsExternals();
                                } else {
                                    this.toastr.error(
                                        'Unidades ya existentes u/o problema.'
                                    );
                                }
                            })
                            .catch(() => {
                                this.toastr.error(
                                    'Error creando las unidades.'
                                );
                            });
                    } else {
                        this.toastr.error(
                            'No se recibió respuesta del servidor.'
                        );
                    }
                })
                .catch((error) => {
                    console.error(
                        'Error en la comunicación con el servidor:',
                        error
                    );
                    this.toastr.error('Ocurrió un error inesperado.');
                })
                .finally(() => {
                    event.target.value = '';
                });
        } else {
            this.toastr.error('Tipo de archivo incorrecto.');
        }
    }

    // EDIT UNIT EXTERNAL

    onEditEvent(index: number, unit: any) {
        if (this.editIndexExternalUnit === null) {
            this.editIndexExternalUnit = index;
            this.backup = {...unit};
        } else if (this.editIndexExternalUnit === index) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: 'Se actualizará la información de la unidad',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, Actualizar!',
                cancelButtonText: 'No, Cancelar.'
            }).then((result) => {
                var date = new Date();
                var isoString = date.toISOString();
                var formattedDateNow = isoString.split('T')[0];
                if (result.isConfirmed) {
                    const keys = Object.keys(unit);
                    const dateFields = {
                        soat: 'Fecha del SOAT vencido',
                        tecnomecanica: 'Fecha de la tecnomecánica vencida',
                        voperacion: 'Fecha de la tarjeta de operación vencida'
                    };
                    for (const key of keys) {
                        if (key) {
                            const validation =
                                this.unitExternalState.units.find(
                                    (existingUnit) =>
                                        existingUnit.id !== unit.id &&
                                        existingUnit.registration_plate ===
                                            unit.registration_plate
                                );
                            if (validation) {
                                this.toastr.error(
                                    'La placa no puede ser igual a la de otra unidad'
                                );
                                return;
                            }
                            const keyNames = {
                                registration_plate: 'Placa',
                                year: 'Modelo (Línea)',
                                brand: 'Marca',
                                vin: 'Número interno',
                                model: 'Clase de vehiculo',
                                noperacion: 'Número operación'
                            };

                            if (unit[key] === '') {
                                this.toastr.error(
                                    'Por favor, completa el campo ' +
                                        keyNames[key]
                                );
                                return;
                            } else if (
                                dateFields[key] &&
                                unit[key] < formattedDateNow
                            ) {
                                this.toastr.error(dateFields[key]);
                                return;
                            }
                        }
                    }

                    this.unitService
                        .updateFuecBackupUnit(this.backup)
                        .then((response) => {
                            if (response.msg) {
                                return this.unitService.updateUnit(unit);
                            } else if (response.error) {
                                return Promise.reject(response.error);
                            }
                        })
                        .then((unitExternal) => {
                            if (unitExternal.msg) {
                                this.toastr.success(
                                    'Unidad actualizada con éxito'
                                );
                            } else {
                                this.toastr.error(
                                    'Error al actualizar la unidad'
                                );
                            }
                            this.getUnitsExternals();
                            this.backup = {};
                        });
                    this.editIndexExternalUnit = null;
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.getUnitsExternals();
                    Swal.fire(
                        'Cancelado',
                        'Los datos de la unidad no han sido actualizados',
                        'error'
                    );
                    this.editIndexExternalUnit = null;
                    this.backup = {};
                }
            });
        } else {
            Swal.fire({
                title: '¿Deseas descartar los cambios actuales de edición?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, Descartar!',
                cancelButtonText: 'No, Mantener.'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.getUnitsExternals();
                    this.editIndexExternalUnit = index;
                    const {id, ...unitWithoutId} = unit;
                    this.backup = {
                        ...unitWithoutId
                    };
                }
            });
        }
    }

    // REMOVE UNIT EXTERNAL

    showDetailInfoDelete(info) {
        this.unitExternalState.id_unit = info.id;
        this.backup = info;
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Se eliminará el vehículo en tu cuenta.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar!',
            cancelButtonText: 'No, cancelar.'
        }).then((result) => {
            if (result.isConfirmed) {
                this.unitService
                    .updateFuecBackupUnit(this.backup)
                    .then((response) => {
                        if (response.msg) {
                            this.unitService
                                .deleteUnit(this.unitExternalState.id_unit)
                                .then((response) => {
                                    if (response) {
                                        Swal.fire(
                                            'Vehiculo eliminado',
                                            '¡Vehículo eliminado correctamente!',
                                            'success'
                                        );
                                        this.getUnitsExternals();
                                        this.backup = {};
                                    } else {
                                        Swal.fire(
                                            'Error',
                                            'No se pudo eliminar el vehículo.',
                                            'error'
                                        );
                                    }
                                })
                                .catch((error) => {
                                    console.error(
                                        'Error al eliminar el vehículo:',
                                        error
                                    );
                                    Swal.fire(
                                        'Error',
                                        'Ocurrió un error al eliminar el vehículo.',
                                        'error'
                                    );
                                });
                        } else if (response.error) {
                            console.log(response.error);
                        }
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelado',
                    'Los datos del vehículo continuan en tu cuenta.',
                    'error'
                );
                this.backup = {};
            }
        });
        this.cd.detectChanges();
    }

    // FILTERS

    paginatorEvent(currentPage: number) {
        this.getUnitsExternals(currentPage);
        this.isReset = false;
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchEvent(searchby: string) {
        this.searchby = searchby;
        this.paginatorEvent(this.currentPage);
    }
}
