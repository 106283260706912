import {ManageResponsiblesComponent} from './pages/manage-responsibles/manage-responsibles.component';
import {ManageContractorsComponent} from './pages/manage-contractors/manage-contractors.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {ManageAccountComponent} from './pages/manage-account/manage-account.component';
import {UnitExternalComponent} from './components/unitExternal/unitExternal.component';
import {ManageRoutesComponent} from './pages/manage-routes/manage-routes.component';
import {ResponsibleComponent} from './components/responsible/responsible.component';
import {FuecFormatComponent} from './components/fuec-format/fuec-format.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {ContractorComponent} from './components/contractor/contractor.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CreateFuecComponent} from './pages/create-fuec/create-fuec.component';
import {ManageFuecComponent} from './pages/manage-fuec/manage-fuec.component';
import {FuecDetailComponent} from './pages/fuec-detail/fuec-detail.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {ModalComponent} from './components/modal/modal.component';
import {RouteComponent} from './components/route/route.component';
import {TableComponent} from './components/table/table.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {LoginComponent} from '@modules/login/login.component';
import {MainComponent} from '@modules/main/main.component';
import {MatSelectModule} from '@angular/material/select';
import {BrowserModule} from '@angular/platform-browser';
import {MatTableModule} from '@angular/material/table';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from '@/app-routing.module';
import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {InlineSVGModule} from 'ng-inline-svg';
import {AppComponent} from './app.component';
import {QRCodeModule} from 'angular2-qrcode';
import {ToastrModule} from 'ngx-toastr';
import {NgModule} from '@angular/core';

registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        TableComponent,
        PaginationComponent,
        CreateFuecComponent,
        ManageAccountComponent,
        ManageFuecComponent,
        ManageResponsiblesComponent,
        ManageRoutesComponent,
        ManageContractorsComponent,
        ModalComponent,
        FuecDetailComponent,
        UnitExternalComponent,
        RouteComponent,
        ContractorComponent,
        ResponsibleComponent,
        FuecFormatComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatSelectModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        }),
        MatFormFieldModule,
        MatDatepickerModule,
        MatTableModule,
        MatPaginatorModule,
        QRCodeModule,
        InlineSVGModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
