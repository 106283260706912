<div class="container-unit-external">
    <h1>Lista de Unidades Externas</h1>
    <div class="Optionsheader">
        <button (click)="downloadTemplate()">Descargar plantilla</button>
        <div class="customBtnExport" (click)="triggerFileInput()">
            <img src="../../../assets/icon/Icon_import.svg" alt="" />
            <p>Subida masiva</p>
        </div>
        <input
            type="file"
            accept=".xlsx"
            (change)="onFileSelected($event)"
            #fileInput
            style="display: none"
        />
    </div>

    <ng-template #actionsTemplate let-index="index" let-$implicit="$implicit">
        <div class="row m-0 p-0 w-100">
            <div
                class="
                    d-flex
                    align-items-center
                    justify-content-center
                    h-100
                    w-100
                "
            >
                <div class="actions-content">
                    <div
                        matTooltip="Editar"
                        class="icon-td"
                        (click)="onEditEvent(index, $implicit)"
                    >
                        <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 200 200"
                            style="enable-background: new 0 0 200 200"
                            xml:space="preserve"
                            class="table-icon"
                        >
                            <path
                                d="M160,120c0,44.2-35.8,80-80,80S0,164.2,0,120c0-44.2,35.8-80,80-80c5.5,0,10.9,0.6,16.1,1.6L81.7,56
                                c-0.6,0-1.2,0-1.7,0c-35.3,0-64,28.7-64,64s28.7,64,64,64s64-28.7,64-64c0-0.6,0-1.2,0-1.7l14.4-14.4C159.4,109.1,160,114.5,160,120
                                z M160.4,0L67.8,92.6l39.6,39.6L200,39.6L160.4,0z M56.5,103.9L48,152l48.1-8.5L56.5,103.9L56.5,103.9z"
                                fill="current"
                            />
                        </svg>
                    </div>
                    <div
                        matTooltip="Eliminar"
                        class="icon-td"
                        (click)="showDetailInfoDelete($implicit)"
                    >
                        <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 200 200"
                            style="enable-background: new 0 0 200 200"
                            xml:space="preserve"
                            class="table-icon"
                        >
                            <path
                                class="st0"
                                d="M160,80v40H40V80H160z M20,100c0,40,40,80,80,80s80-40,80-80s-40-80-80-80S20,60,20,100 M0,100C0,40,40,0,100,0
                                s100,40,100,100s-40,100-100,100S0,160,0,100L0,100z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <div class="containerTable">
        <app-table
            [model]="unitExternalModel"
            [removeCard]="false"
            (paginatorEvent)="paginatorEvent($event)"
            (pageSizeEvent)="pageSizeEvent($event)"
            (searchEvent)="searchEvent($event)"
            (getRecords)="getTotalUnitsExternals($event)"
            [hideIndex]="true"
            [actionsTemplate]="actionsTemplate"
            [isReset]="isReset"
            [isEdit]="true"
            [editIndexExternalUnit]="editIndexExternalUnit"
        ></app-table>
    </div>
</div>
