import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FuecService {
    constructor(private http: HttpClient) {}

    async getHistoryFuecs(page?: number, pageSize?: number, searchby?: string) {
        let fuecs = await this.http
            .get<any>(
                `/api/fuec/history/${page ? `page=${page}` : ``}${
                    pageSize ? `&pagesize=${pageSize}` : ``
                }${searchby ? `&searchby=${searchby}` : ''}`
            )
            .toPromise();
        if (!fuecs.error) {
            return fuecs;
        } else {
            return null;
        }
    }

    async createFuec(fuec: any) {
        let postFuec = await this.http
            .post<any>('/api/fuec/fuec/new', {
                jsonrpc: '2.0',
                params: fuec
            })
            .toPromise();
        let response;
        if (!postFuec.error) {
            response = {
                msg: 'okay'
            };
            return response;
        } else {
            response = {
                error: postFuec.error
            };
            return response;
        }
    }

    async getLastContractNumber() {
        let response = await this.http
            .get<any>('/api/fuec/fuec/last_contract_number')
            .toPromise();
        return response;
    }

    async getDriversFuecs(page?: number, pageSize?: number, searchby?: string, initialDrives?: boolean, driversJSON?: any) {
        let body = {
            page: page,
            pageSize: pageSize,
            searchby: searchby,
            initialDrives: initialDrives ? initialDrives : false,
            drivers: driversJSON ? driversJSON : {}
        };
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let drivers = await this.http
            .post<any>(`/api/fuec/drivers`, body, {headers})
            .toPromise();
        if (!drivers.error) {
            return drivers;
        } else {
            return null;
        }
    }

    async getUnitsFuecs() {
        let units = await this.http.get<any>(`/api/fuec/units`).toPromise();
        if (!units.error) {
            return units;
        } else {
            return null;
        }
    }

    async getFuecDetail(url: string) {
        let fuec = await this.http
            .get<any>(`/api/fuec/fuec/detail/${url}`)
            .toPromise();
        if (!fuec.error) {
            return fuec;
        } else {
            return null;
        }
    }
}
