import {
    AccountState,
    ContractorState,
    DriverState,
    RouteState,
    UnitState,
    ViewState
} from './create-fuec.component-interface';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ResponsibleService} from '@services/responsible.service';
import {ContractorService} from '@services/contractor.service';
import {MinTransporte} from 'assets/images/MinTransporte';
import {ContextService} from '@services/context.service';
import {TableViewModel} from '@components/models/table';
import {SwitchService} from '@services/switch.service';
import {RouteService} from '@services/route.service';
import {FuecService} from '@services/fuec.service';
import {UnitService} from '@services/unit.service';
import {AppService} from '@services/app.service';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {superIT} from 'assets/images/superIT';
import pdfMake from 'pdfmake/build/pdfmake';
import {ToastrService} from 'ngx-toastr';
import {Vehicle} from '@/models/vehicle';
import {Account} from '@/models/account';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {timer} from 'rxjs';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-create-fuec',
    templateUrl: './create-fuec.component.html',
    styleUrls: ['./create-fuec.component.scss']
})
export class CreateFuecComponent implements OnInit {
    private drivers: any;
    public HasExternalResources: any;

    viewState: ViewState = {
        show_view_contractor: false,
        show_view_responsible: false,
        show_view_route: false,
        isTemporaryVehicle: false,
        show_view_units_external: false,
        show_modal: false
    };

    accountState: AccountState = {
        account: Account,
        code_hab: '',
        date_hab: '',
        fuec_serie: [],
        resolution_fuec: '',
        super_token: '',
        token: '',
        wialon_user_name: ''
    };

    routeState: RouteState = {
        routes: {},
        route_name: '',
        route_description: '',
        route_origin: '',
        route_destination: ''
    };

    unitState: UnitState = {
        units: [],
        tecnomecanicaDate: Date,
        vehicle: Vehicle,
        soatDate: Date,
        wiaUnitsLoaded: false,
        voDate: Date,
        isExternal: false
    };

    contractorState: ContractorState = {
        contractors: [],
        contractor_name: '',
        contractor_nit: ''
    };

    responsibleState = {
        responsibles: [],
        responsables: new FormControl()
    };

    driverState: DriverState = {
        drivers: [],
        drivers_fuec: [],
        drivers_ids: [],
        drivers_selected: [],
        drivers_count: 0
    };

    public fuecForm: FormGroup;
    public vehicleForm: FormGroup;
    fuec_contract_number: string;
    contract_number: string;
    contract_object: string;
    searchby: string = '';
    endDate: Date;
    currentPage: number = 1;
    pageSize: number = 5;
    current_month: string = new Date(Date.now()).getMonth().toString();
    current_year: string = new Date(Date.now()).getFullYear().toString();
    minDate: string = this.current_year + '-01-01';
    maxDate: string =
        this.current_month == '11'
            ? (new Date(Date.now()).getFullYear() + 1).toString() + '-12-31'
            : this.current_year + '-12-31';

    isVehicleExternal: boolean;
    excelData = [];

    constructor(
        private appService: AppService,
        private fuecService: FuecService,
        private contractorService: ContractorService,
        private responsibleService: ResponsibleService,
        private unitService: UnitService,
        private contextService: ContextService,
        private routeService: RouteService,
        private cd: ChangeDetectorRef,
        private modalService: SwitchService,
        private toastr: ToastrService,
        private router: Router
    ) {
        const timer$ = timer(100, 20000);
        timer$.subscribe(() => this.syncUnits());
    }

    ngOnInit(): void {
        this.contextService.drives$.subscribe((drivers) => {
            this.drivers = drivers;
        });
        this.loadNextContractNumber();
        this.initForm();
        this.endDate = new Date(this.fuecForm.value.date_end);
        this.getResponsibles();
        this.getContractors();
        this.getAccountInfo();
        this.getDrivers(this.drivers);
        this.getRoutes();

        this.modalService.$modal.subscribe((value) => {
            this.viewState.show_modal = value;
        });
    }

    initForm(): void {
        this.fuecForm = new FormGroup({
            contract_number: new FormControl(null, Validators.required),
            contractor_id: new FormControl(null, Validators.required),
            responsible_ids: new FormControl(null, Validators.required),
            contract_object: new FormControl(null, Validators.required),
            route_id: new FormControl(null, Validators.required),
            agreement: new FormControl('no-aplica', Validators.required),
            agreement_desc: new FormControl(''),
            date_start: new FormControl(null, Validators.required),
            date_end: new FormControl(null, Validators.required),
            vehicle_id: new FormControl(null),
            driver_ids: new FormControl(null, Validators.required),
            token: new FormControl(this.accountState.token),
            w_uname: new FormControl(this.accountState.wialon_user_name)
        });
        this.vehicleForm = new FormGroup({
            registration_plate: new FormControl('', Validators.required),
            year: new FormControl('', [
                Validators.required,
                Validators.pattern(/^(19|20)\d{2}$/)
            ]),
            brand: new FormControl('', Validators.required),
            vin: new FormControl('', Validators.required),
            model: new FormControl('', Validators.required),
            soat: new FormControl('', Validators.required),
            tecnomecanica: new FormControl('', Validators.required),
            voperacion: new FormControl('', Validators.required),
            noperacion: new FormControl('', Validators.required)
        });
    }

    loadNextContractNumber() {
        this.fuecService
            .getLastContractNumber()
            .then((response) => {
                if (response && response.next_contract_number) {
                    this.fuecForm.patchValue({
                        contract_number: response.next_contract_number
                    });
                }
            })
            .catch((error) => {
                console.error('Error al cargar el número de contrato:', error);
            });
    }

    // Account

    getAccountInfo(): void {
        this.appService.getAccountInfo().then((account) => {
            var date = new Date(account.fuec_fecha_hab);
            var year = date.getFullYear();
            var yearStr = year.toString();
            var hb = yearStr.slice(yearStr.length - 2, yearStr.length);
            this.accountState.token = account.token;
            this.accountState.super_token = account.super_token;
            this.accountState.wialon_user_name = account.name;
            this.accountState.date_hab = hb;
            this.accountState.fuec_serie = this.appService.zfill(
                account.fuec_end_number + 1,
                4
            );
            this.accountState.code_hab = account.fuec_code_hab;
            this.accountState.resolution_fuec = account.fuec_resolucion;
            this.accountState.account = account;
            this.HasExternalResources = account.HasExternalResources
        });
    }

    // Contractor

    onChangeContractorName() {
        var contractorName = this.contractorState.contractors.find(
            (contractor: any) =>
                parseInt(contractor.id) ===
                parseInt(this.fuecForm.value.contractor_id)
        );

        this.contractorState.contractor_name = contractorName.company_name;
        this.contractorState.contractor_nit = contractorName.doc_number;
    }

    openModalCreateContractor() {
        this.modalService.$modal.emit(true);
        this.viewState.show_view_contractor = true;
    }

    getContractors() {
        this.contractorService.getContractorsFuecs().then((contractors) => {
            this.contractorState.contractors = contractors.result;
            if (this.viewState.show_modal) {
                this.closeAllModals();
            }
            this.cd.detectChanges();
        });
    }

    // Responsible

    onChangeResponsable() {
        if (this.responsibleState.responsables.value.length <= 1) {
            this.fuecForm.controls['responsible_ids'].setValue(
                this.responsibleState.responsables.value[0].id
            );
        } else {
            this.responsibleState.responsables.setValue(
                this.fuecForm.value.responsible_ids
            );
        }
    }

    openModalCreateResponsible() {
        this.modalService.$modal.emit(true);
        this.viewState.show_view_responsible = true;
    }

    getResponsibles() {
        this.responsibleService.getResponsiblesFuecs().then((responsibles) => {
            this.responsibleState.responsibles = responsibles.result;
            if (this.viewState.show_modal) {
                this.closeAllModals();
            }
            this.cd.detectChanges();
        });
    }

    // Routes

    onChangeRoute() {
        var route = this.routeState.routes.find(
            (route: any) =>
                parseInt(route.id) === parseInt(this.fuecForm.value.route_id)
        );
        this.routeState.route_name = route.name;
        this.routeState.route_description = route.description;
        this.routeState.route_origin = route.origin;
        this.routeState.route_destination = route.destination;
    }

    openModalCreateRoute() {
        this.modalService.$modal.emit(true);
        this.viewState.show_view_route = true;
    }

    getRoutes() {
        this.routeService.getRoutesFuecs().then((routes) => {
            this.routeState.routes = routes.result;
            if (this.viewState.show_modal) {
                this.closeAllModals();
            }
            this.cd.detectChanges();
        });
    }

    // Units

    syncUnits() {
        this.fuecService
            .getUnitsFuecs()
            .then((units) => {
                this.unitState.units = units.result;
                if (this.HasExternalResources){
                    this.unitService
                        .getUnits()
                        .then((units) => {
                            if (Array.isArray(units)) {
                                this.unitState.units = [
                                    ...this.unitState.units,
                                    ...units
                                ];
                            } else {
                                console.error(
                                    'Error: units no contiene un array válido'
                                );
                            }
                            this.unitState.wiaUnitsLoaded = true;
                            this.unitState.units.forEach((unit) => {
                                this.processVehicleDates(unit);
                            });
                        })
                        .catch((error) => {
                            console.error(
                                'Error al obtener unidades de unitService:',
                                error
                            );
                        });
                } else {
                    this.unitState.units.forEach((unit) => {
                        this.processVehicleDates(unit);
                    });
                }
            })
            .catch((error) => {
                console.error(
                    'Error al obtener unidades de fuecService:',
                    error
                );
            });
    }

    processVehicleDates(vehicle) {
        if (vehicle.soat) {
            var soatDate = new Date(
                Number.parseInt(vehicle.soat.toString()) * 1000
            );
            soatDate.setFullYear(soatDate.getFullYear() + 1);
            vehicle.soat = soatDate;
            vehicle.soatDate = soatDate;
        }

        if (vehicle.tecnomecanica) {
            var tecnomecanicaDate = new Date(
                Number.parseInt(vehicle.tecnomecanica.toString()) * 1000
            );
            tecnomecanicaDate.setFullYear(tecnomecanicaDate.getFullYear() + 1);
            vehicle.tecnomecanica = tecnomecanicaDate;
            vehicle.tecnomecanicaDate = tecnomecanicaDate;
        }

        if (vehicle.voperacion) {
            var voDate = new Date(
                Number.parseInt(vehicle.voperacion.toString()) * 1000
            );
            voDate.setFullYear(voDate.getFullYear() + 2);
            vehicle.voperacion = voDate;
            vehicle.voDate = voDate;
        }
    }

    onChangeVehicle() {
        const dateEndValue = this.fuecForm.get('date_end')?.value;
        const unitValue = this.fuecForm.get('vehicle_id')?.value;

        if (unitValue === '') {
            this.unitState.vehicle = {};
            this.unitState.isExternal = false;
            return;
        }

        if (!dateEndValue) {
            this.toastr.error(
                'Primero llene el campo de Fecha de vencimiento del contrato'
            );
            this.fuecForm.get('vehicle_id')?.reset();
            return;
        }

        this.viewState.isTemporaryVehicle = false;
        this.unitState.vehicle = this.unitState.units.find(
            (unit: any) => unit.name === this.fuecForm.value.vehicle_id
        );

        if (this.unitState.vehicle?.isExternal) {
            this.unitState.isExternal = true;
        } else {
            this.unitState.isExternal = false;
        }

        if (this.unitState.vehicle) {
            this.endDate = new Date(this.fuecForm.value.date_end);
        }

        this.cd.detectChanges();
    }

    onCreateVehicle() {
        this.unitState.isExternal = false;
        this.viewState.isTemporaryVehicle = !this.viewState.isTemporaryVehicle;
        this.unitState.vehicle = null;
        (
            document.querySelector(
                'input[formControlName="vehicle_id"]'
            ) as HTMLInputElement
        ).value = '';
        if (this.viewState.isTemporaryVehicle) {
            this.vehicleForm.reset();
            this.unitState.vehicle = null;
        }
    }

    saveTemporaryCar() {
        if (!this.vehicleForm.valid) {
            this.toastr.error('Campos faltantes');
            return;
        }

        for (let index = 0; index < this.unitState.units.length; index++) {
            const unit = this.unitState.units[index];
            if (
                unit.registration_plate ===
                    this.vehicleForm.value.registration_plate ||
                unit.name === this.vehicleForm.value.registration_plate
            ) {
                this.toastr.error('Placa ya existente');
                return;
            }
        }

        this.unitState.vehicle = this.vehicleForm.value;
        this.endDate = new Date(this.fuecForm.value.date_end);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        this.unitState.vehicle.soatDate = new Date(
            this.unitState.vehicle.soat + 'T00:00:00'
        );
        if (this.unitState.vehicle.soatDate < today) {
            this.toastr.error('El SOAT está vencido.');
            return;
        }
        this.unitState.vehicle.soatDate.setFullYear(
            this.unitState.vehicle.soatDate.getFullYear()
        );
        this.unitState.vehicle.soat = this.unitState.vehicle.soatDate;

        this.unitState.vehicle.tecnomecanicaDate = new Date(
            this.unitState.vehicle.tecnomecanica + 'T00:00:00'
        );
        if (this.unitState.vehicle.tecnomecanicaDate < today) {
            this.toastr.error('La revisión técnico-mecánica está vencida.');
            return;
        }
        this.unitState.vehicle.tecnomecanicaDate.setFullYear(
            this.unitState.vehicle.tecnomecanicaDate.getFullYear()
        );

        this.unitState.vehicle.tecnomecanica =
            this.unitState.vehicle.tecnomecanicaDate;

        this.unitState.vehicle.voDate = new Date(
            this.unitState.vehicle.voperacion + 'T00:00:00'
        );
        if (this.unitState.vehicle.voDate < today) {
            this.toastr.error('La tarjeta de operación está vencida.');
            return;
        }
        this.unitState.vehicle.voDate.setFullYear(
            this.unitState.vehicle.voDate.getFullYear()
        );
        this.unitState.vehicle.voperacion = this.unitState.vehicle.voDate;

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Se guardará el vehículo en tu cuenta.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, guardar!',
            cancelButtonText: 'No, cancelar.'
        }).then((result) => {
            if (result.isConfirmed) {
                this.unitService
                    .createUnit(this.unitState.vehicle)
                    .then((unit_id) => {
                        if (unit_id) {
                            Swal.fire(
                                'Vehículo guardado',
                                '¡Vehículo guardado correctamente!',
                                'success'
                            );
                            this.unitState.vehicle.id = unit_id;
                            this.viewState.isTemporaryVehicle = false;
                            this.isVehicleExternal = true;
                            setTimeout(() => {
                                this.syncUnits();
                            }, 2000);
                        } else {
                            Swal.fire(
                                'Error',
                                'No se pudo guardar el vehículo.',
                                'error'
                            );
                        }
                    })
                    .catch((error) => {
                        console.error('Error al guardar el vehículo:', error);
                        Swal.fire(
                            'Error',
                            'Ocurrió un error al guardar el vehículo.',
                            'error'
                        );
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelado',
                    'Los datos del vehículo no han sido guardados.',
                    'error'
                );
                this.unitState.vehicle = {};
                this.viewState.isTemporaryVehicle = false;
            }
        });
        this.cd.detectChanges();
    }

    deleteVehicleExternal = () => {
        var soatDate = new Date(this.unitState.vehicle.soat)
            .toISOString()
            .split('T')[0];
        var tecnomecanicaDate = new Date(this.unitState.vehicle.tecnomecanica)
            .toISOString()
            .split('T')[0];
        var voperacionDate = new Date(this.unitState.vehicle.voperacion)
            .toISOString()
            .split('T')[0];
        var backup = {
            id: this.unitState.vehicle.id,
            registration_plate: this.unitState.vehicle.registration_plate,
            year: this.unitState.vehicle.year,
            brand: this.unitState.vehicle.brand,
            vin: this.unitState.vehicle.vin,
            model: this.unitState.vehicle.model,
            soat: soatDate,
            tecnomecanica: tecnomecanicaDate,
            voperacion: voperacionDate,
            noperacion: this.unitState.vehicle.noperacion
        };

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Se eliminará el vehículo en tu cuenta.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar!',
            cancelButtonText: 'No, cancelar.'
        }).then((result) => {
            if (result.isConfirmed) {
                this.unitService
                    .updateFuecBackupUnit(backup)
                    .then((response) => {
                        if (response.msg) {
                            this.unitService
                                .deleteUnit(this.unitState.vehicle.id)
                                .then((response) => {
                                    if (response) {
                                        Swal.fire(
                                            'Vehiculo eliminado',
                                            '¡Vehículo eliminado correctamente!',
                                            'success'
                                        );
                                        this.unitState.vehicle = {};
                                        this.unitState.isExternal = false;
                                        this.fuecForm
                                            .get('vehicle_id')
                                            ?.reset();
                                        this.syncUnits();
                                    } else {
                                        Swal.fire(
                                            'Error',
                                            'No se pudo eliminar el vehículo.',
                                            'error'
                                        );
                                    }
                                })
                                .catch((error) => {
                                    console.error(
                                        'Error al eliminar el vehículo:',
                                        error
                                    );
                                    Swal.fire(
                                        'Error',
                                        'Ocurrió un error al eliminar el vehículo.',
                                        'error'
                                    );
                                });
                        } else if (response.error) {
                            console.log(response.error);
                        }
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelado',
                    'Los datos del vehículo continuan en tu cuenta.',
                    'error'
                );
            }
        });
        this.cd.detectChanges();
    };

    openModalUnitsExternals() {
        this.modalService.$modal.emit(true);
        this.viewState.show_view_units_external = true;
        document.body.classList.add('no-scroll');
        (
            document.querySelector(
                'input[formControlName="vehicle_id"]'
            ) as HTMLInputElement
        ).value = '';
        this.unitState.vehicle = {};
        this.unitState.isExternal = false;
    }

    // Drivers

    driversModel: TableViewModel = {
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Nombre', key: 'name', filter: false, data: []},
            {
                title: 'Cédula',
                key: 'cc',
                filter: false,
                data: []
            },
            {
                title: 'Licencia',
                key: 'licencia',
                filter: false,
                data: []
            },
            {
                title: 'Vencimiento',
                key: 'vencimiento',
                filter: false,
                data: []
            }
        ],
        records: [],
        totalRecords: [],
        showFilters: false,
        enableActions: false,
        removeCard: false
    };

    selectDriver(user: any) {
        var driver = this.driverState.drivers.result.find(
            (drive) => drive.id === user.id
        );

        var driverExist = this.driverState.drivers_selected.find(
            (driveId) => driveId === user.id
        );

        if (this.fuecForm.value.date_end && this.fuecForm.value.date_start) {
            if (driver && !driverExist) {
                if (driver.vencimiento > this.fuecForm.value.date_end) {
                    if (this.driverState.drivers_selected.length < 3) {
                        this.driverState.drivers_selected.push(driver.id);
                        this.driverState.drivers_fuec.push(driver);
                        this.driverState.drivers_ids.push(driver.name);
                    } else {
                        this.toastr.error(
                            'Lo siento, no puede seleccionar mas de tres conductores'
                        );
                    }
                } else {
                    this.toastr.error(
                        'Este conductor no se puede seleccionar ya que su pase vence antes de la fecha de terminacion del fuec'
                    );
                }
            } else {
                this.removeItemFromArr(
                    this.driverState.drivers_selected,
                    driver.id
                );
                this.removeItemFromArr(this.driverState.drivers_fuec, driver);
                this.driverState.drivers_ids =
                    this.driverState.drivers_ids.filter(
                        (d) => d !== driver.name
                    );
            }
        } else {
            this.toastr.error(
                'Lo siento no puede hacer esto, debe primero seleccionar la fecha del fuec'
            );
        }
        this.fuecForm.controls['driver_ids'].setValue(
            this.driverState.drivers_ids
        );
    }

    getDrivers(drivers: any): any {
        this.fuecService
            .getDriversFuecs(
                this.currentPage,
                this.pageSize,
                this.searchby,
                false,
                drivers
            )
            .then((drivers) => {
                this.driverState.drivers = drivers.result;
                this.driverState.drivers_count = drivers.result.total_count;
                this.driversModel.records = this.driverState.drivers.result;
                this.driversModel.pagination = {
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    pageCount: drivers.count,
                    rowCount: this.driverState.drivers_count
                };
            });
    }

    paginatorEvent(currentPage: number) {
        this.fuecService
            .getDriversFuecs(
                currentPage,
                this.pageSize,
                this.searchby,
                false,
                this.drivers
            )
            .then((drivers) => {
                if (drivers.result) {
                    this.driverState.drivers = drivers.result;
                    this.driverState.drivers_count = drivers.result.total_count;
                    this.driversModel.records = this.driverState.drivers.result;
                    this.driversModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: drivers.count,
                        rowCount: this.driverState.drivers_count
                    };
                    this.cd.detectChanges();
                } else {
                    this.toastr.error('Failure to page drivers.');
                }
            })
            .catch((e) => {
                this.toastr.error(
                    'Failed to fetch drivers. Please try again later. ' + e
                );
            });
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchEvent(searchby: string) {
        this.searchby = searchby;
        this.paginatorEvent(this.currentPage);
    }

    // Fuec

    createFuec() {
        this.onChangeContractNumber();

        let textError = 'Falta';
        let hasError = false;

        const fieldsToValidate = [
            {field: 'contract_number', message: 'numero de contrato'},
            {field: 'contractor_id', message: 'contratista'},
            {field: 'responsible_ids', message: 'responsable'},
            {field: 'contract_object', message: 'objeto de contrato'},
            {field: 'route_id', message: 'ruta'},
            {field: 'date_start', message: 'fecha inicial'},
            {field: 'date_end', message: 'fecha final'},
            {field: 'agreement', message: 'tipo de convenio'},
            {field: 'vehicle_id', message: 'vehiculo'},
            {field: 'driver_ids', message: 'conductor'}
        ];

        fieldsToValidate.forEach(({field, message}) => {
            if (!this.fuecForm.controls[field].valid) {
                textError += `, ${message}`;
                hasError = true;
            }
        });

        if (!this.unitState.vehicle) {
            textError += ', el vehiculo';
            hasError = true;
        }

        if (hasError) {
            this.toastr.error(textError);
            return;
        }

        var soatDate = new Date(this.unitState.vehicle.soat)
            .toISOString()
            .split('T')[0];
        var tecnomecanicaDate = new Date(this.unitState.vehicle.tecnomecanica)
            .toISOString()
            .split('T')[0];
        var voperacionDate = new Date(this.unitState.vehicle.voperacion)
            .toISOString()
            .split('T')[0];

        if (
            soatDate < this.fuecForm.value.date_end ||
            tecnomecanicaDate < this.fuecForm.value.date_end ||
            voperacionDate < this.fuecForm.value.date_end
        ) {
            this.toastr.error('El vehiculo no cumple las fechas establecidas');
            return;
        }

        if (this.unitState.vehicle?.type === 'external unit') {
            this.isVehicleExternal = true;
        }

        if (
            this.fuecForm.valid &&
            this.unitState.vehicle &&
            this.unitState.vehicle.soat &&
            this.unitState.vehicle.tecnomecanica
        ) {
            var fuec = {
                contract_number: this.fuecForm.value.contract_number,
                fuec_series: this.fuec_contract_number,
                contractor_id: parseInt(this.fuecForm.value.contractor_id),
                contractor_backup: JSON.stringify({
                    company_name: '',
                    nit: '',
                    name: '',
                    email: '',
                    phone: '',
                    address: '',
                    city: ''
                }),
                responsible_ids: [this.fuecForm.value.responsible_ids],
                responsible_ids_backup: JSON.stringify({
                    name: '',
                    id_type: '',
                    id_document: '',
                    email: '',
                    city: '',
                    address: '',
                    phone: ''
                }),
                contract_object: this.fuecForm.value.contract_object,
                route_id: parseInt(this.fuecForm.value.route_id),
                route_backup: JSON.stringify({
                    detail: '',
                    destination: '',
                    name: '',
                    origin: ''
                }),
                agreement: this.fuecForm.value.agreement,
                agreement_desc: this.fuecForm.value.agreement_desc,
                date_start: this.fuecForm.value.date_start,
                date_end: this.fuecForm.value.date_end,
                vehicle_id: !this.isVehicleExternal
                    ? this.unitState.vehicle.id
                    : null,
                driver_ids: this.fuecForm.value.driver_ids,
                vehicle_backup: JSON.stringify({
                    registration_plate: '',
                    year: '',
                    brand: '',
                    vin: '',
                    model: '',
                    soat: '',
                    tecnomecanica: '',
                    voperacion: '',
                    noperacion: ''
                }),
                account: this.accountState.account,
                unit_id: this.isVehicleExternal
                    ? this.unitState.vehicle.id
                    : null
            };

            this.fuecService
                .createFuec(fuec)
                .then((response) => {
                    if (response.msg) {
                        this.openPDF();
                        this.router.navigate(['/history']);
                    } else {
                        this.toastr.error(
                            'Ocurrió un problema en la creación del fuec.'
                        );
                    }
                })
                .catch((error) => {
                    console.error('Error al crear fuec:', error);
                    this.toastr.error(
                        'Hubo un problema al crear el fuec. Por favor, intente de nuevo.'
                    );
                });
        }
    }

    onChangeContractNumber() {
        let contractNumber = this.fuecForm.value.contract_number.toString();
        let length = contractNumber.length;

        if (length > 4) {
            this.contract_number = contractNumber.slice(length - 4, length);
        } else {
            this.contract_number = this.zfill(contractNumber, 4);
        }

        this.onChangeFuecContract();
    }

    onChangeFuecContract() {
        var fuec_contract =
            this.accountState.code_hab +
            this.accountState.resolution_fuec +
            this.accountState.date_hab +
            this.current_year +
            this.contract_number +
            this.accountState.fuec_serie;

        this.fuec_contract_number = fuec_contract;
    }

    // PDF

    openPDF() {
        var json_drivers = [];
        const emailText = this.appService.insertLineBreaks(
            this.accountState.account.email,
            35
        );
        json_drivers = this.driverState.drivers_fuec.map((driver) => {
            const data = [
                {
                    text: driver.name,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: driver.cc,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: driver.licencia,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {},
                {
                    text: driver.vencimiento,
                    alignment: 'center',
                    colSpan: 6
                },
                {},
                {},
                {},
                {},
                {}
            ];

            return data;
        });
        var json_responsibles = [];
        json_responsibles = this.responsibleState.responsables.value.map(
            (responsible) => {
                const data = [
                    {
                        text: responsible.fullname,
                        alignment: 'center',
                        colSpan: 6
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {
                        text: responsible.doc_number,
                        alignment: 'center',
                        colSpan: 6
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {
                        text: responsible.address,
                        alignment: 'center',
                        colSpan: 6
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {
                        text: responsible.phone,
                        alignment: 'center',
                        colSpan: 6
                    },
                    {},
                    {},
                    {},
                    {},
                    {}
                ];

                return data;
            }
        );
        var docDefinition = {
            pageMargins: [40, 90, 40, 60],
            header: {
                columns: [
                    {
                        image: MinTransporte,
                        width: 260,
                        height: 60,
                        alignment: 'left',
                        opacity: 1,
                        margin: [40, 10]
                    },
                    {
                        image: `data:image/jpeg;base64,${this.accountState.account.logo}`,
                        width: 100,
                        height: 70,
                        alignment: 'right',
                        opacity: 0.9,
                        fit: [100, 70],
                        margin: [-25, 15]
                    }
                ],
                columnGap: 80
            },
            content: [
                {
                    style: 'tableExample',
                    table: {
                        body: [
                            [
                                {
                                    text: [
                                        `FORMATO ÚNICO DE EXTRACTO DEL CONTRATO DEL SERVICIO \n PÚBLICO DE TRANSPORTE TERRESTRE AUTOMOTOR ESPECIAL \n NO. `,
                                        {
                                            text: this.formatFuecSeries(),
                                            color: '#FE0000'
                                        }
                                    ],
                                    colSpan: 24,
                                    alignment: 'center'
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Razon social: ',
                                            bold: true
                                        },
                                        this.accountState.account.razonsocial
                                    ],
                                    colSpan: 14
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'NIT: ',
                                            bold: true
                                        },
                                        this.accountState.account.id_document
                                    ],
                                    colSpan: 10
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Contrato No. ',
                                            bold: true
                                        },
                                        this.fuecForm.value.contract_number
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Contratante: ',
                                            bold: true
                                        },
                                        this.contractorState.contractor_name
                                    ],
                                    colSpan: 14
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'NIT: ',
                                            bold: true
                                        },
                                        this.contractorState.contractor_nit
                                    ],
                                    colSpan: 10
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Objeto del contrato: ',
                                            bold: true
                                        },
                                        this.contract_object
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Ruta: ',
                                            bold: true
                                        },
                                        this.routeState.route_name
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Descripcion: ',
                                            bold: true
                                        },
                                        this.routeState.route_description
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Origen - Destino: ',
                                            bold: true
                                        },
                                        this.routeState.route_origin &&
                                        this.routeState.route_destination
                                            ? `${this.routeState.route_origin} - ${this.routeState.route_destination}`
                                            : this.routeState.route_origin ||
                                              this.routeState.route_destination
                                            ? `${
                                                  this.routeState
                                                      .route_origin ||
                                                  this.routeState.route_name
                                              } - ${
                                                  this.routeState
                                                      .route_destination ||
                                                  this.routeState.route_name
                                              }`
                                            : this.routeState.route_name
                                    ],
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Convenio \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.agreement == 'cv'
                                            ? this.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Consorcio \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.agreement == 'cs'
                                            ? this.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Union temporal \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.agreement == 'ut'
                                            ? this.agreement_desc
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Otro \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.agreement ==
                                        'no-aplica'
                                            ? 'no-aplica'
                                            : ''
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Vigencia del contrato:',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Desde: \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.date_start
                                    ],
                                    alignment: 'center',
                                    colSpan: 12
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Hasta: \n',
                                            bold: true
                                        },
                                        this.fuecForm.value.date_end
                                    ],
                                    alignment: 'center',
                                    colSpan: 12
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Características del vehículo',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Placa',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Modelo',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Marca',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Clase',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Interno',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Operación',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: this.unitState.vehicle
                                        .registration_plate
                                        ? this.unitState.vehicle
                                              .registration_plate
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.unitState.vehicle.year
                                        ? this.unitState.vehicle.year
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.unitState.vehicle.brand
                                        ? this.unitState.vehicle.brand
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.unitState.vehicle.model
                                        ? this.unitState.vehicle.model
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text:
                                        this.unitState.vehicle &&
                                        this.unitState.vehicle.vin
                                            ? this.unitState.vehicle.vin
                                            : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {},
                                {
                                    text: this.unitState.vehicle.noperacion
                                        ? this.unitState.vehicle.noperacion
                                        : '',
                                    alignment: 'center',
                                    colSpan: 4
                                },
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Conductores',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Nombres y Apellidos',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Identificación',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Licencia',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Vigencia',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            //drivers
                            ...json_drivers,
                            [
                                {
                                    text: [
                                        {
                                            text: 'Responsables',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 24
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            [
                                {
                                    text: [
                                        {
                                            text: 'Nombres y Apellidos',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Número Identificacion',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Dirección',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: 'Teléfono',
                                            bold: true
                                        }
                                    ],
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ],
                            //responsibles
                            ...json_responsibles,
                            [
                                {
                                    qr: `https://fuec.monitoringinnovation.com/fuec/${this.fuec_contract_number}`,
                                    alignment: 'center',
                                    fit: 100,
                                    colSpan: 6,
                                    margin: [0, 10]
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: `${this.accountState.account.razonsocial} \n ${this.accountState.account.address} \n ${this.accountState.account.mobile} \n ${emailText}`,
                                    alignment: 'center',
                                    colSpan: 6
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    image: superIT,
                                    width: 80,
                                    alignment: 'center',
                                    colSpan: 6,
                                    margin: [0, 5]
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    image: `data:image/jpeg;base64,${this.accountState.account.signature}`,
                                    width: 90,
                                    alignment: 'center',
                                    colSpan: 6,
                                    margin: [0, 5]
                                },
                                {},
                                {},
                                {},
                                {},
                                {}
                            ]
                        ]
                    }
                },
                {
                    text: 'INSTRUCTIVO PARA LA DETERMINACION DEL NUMERO CONSECUTIVO DEL FUEC',
                    pageBreak: 'before',
                    alignment: 'center',
                    fontSize: 14,
                    bold: true
                },
                {
                    margin: [25, 10],
                    columns: [
                        [
                            {
                                text: 'El Formato Unico de Extracto del Contrato "FUEC" estará constituido por los siguientes números:',
                                fontSize: 10,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'a) Los tres primeros dígitos de izquierda a derecha correcponderán al código de la Dirección Territorial que otorgó la habilitación o de aquella a la cual se hubiese trasladado la Empresa de Servicio público de Transporte Terrestre Automotor Especial;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Antioquia-Choco',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '305'
                                    },
                                    {
                                        width: '*',
                                        text: 'Huila-Caquetá ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '441'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Atlántico ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '208'
                                    },
                                    {
                                        width: '*',
                                        text: 'Magdalena',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '247'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Bolívar-San Andrés y Providencia',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '213'
                                    },
                                    {
                                        width: '*',
                                        text: 'Meta-Vaupés-Vichada ',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '550'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Boyacá-Casanare',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '415'
                                    },
                                    {
                                        width: '*',
                                        text: 'Nariño-Putumayo',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '352'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Caldas',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '317'
                                    },
                                    {
                                        width: '*',
                                        text: 'N/Santander-Arauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '454'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Cauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '319'
                                    },
                                    {
                                        width: '*',
                                        text: 'Quindio',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '363'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'César',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '220'
                                    },
                                    {
                                        width: '*',
                                        text: 'Risaralda',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '366'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Córdoba-Sucre',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '223'
                                    },
                                    {
                                        width: '*',
                                        text: 'Santander',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '468'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Cundinamarca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '425'
                                    },
                                    {
                                        width: '*',
                                        text: 'Tolima',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '473'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                width: '*',
                                alignment: 'center',
                                fontSize: 7,
                                margin: [20, 5],
                                columns: [
                                    {
                                        width: '*',
                                        text: 'Guajira',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '241'
                                    },
                                    {
                                        width: '*',
                                        text: 'Valle del Cauca',
                                        alignment: 'left',
                                        bold: true
                                    },
                                    {
                                        width: 90,
                                        alignment: 'right',
                                        text: '376'
                                    }
                                ],
                                columnGap: 10
                            },
                            {
                                ul: [
                                    'b) Los cuatro dígitos siguientes señalarán el número de resolución mediante la cual se otorgó la habilitación de la Empresa. En caso que la resolución no tenga estos dígitos, los faltantes serán completados con ceros a la izquierda;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'c) Los dos siguientes dígitos, corresponderán a los dos últimos del año en que la empresa fue habilitada;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    'd) Acontinuación, cuatro dígitos que corresponderán al año en el que se expide el extracto del contrato;'
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    `e) Posteriormente, cuatro dígitos que identifican el número del contrato. La numeración debe ser consecutiva, establecida por cada empresa y continuará con la numeración dada a los contratos de prestación del servicios celebrados para el transporte de estudiantes, empleados, turistas, usuarios del servicio de salud, grupos específicos de usuarios, en vigencia de la resolución 3068 de 2014.
                                    `
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                ul: [
                                    `f) Finalmente, los cuatro últimos dígitos corresponderán al número consecutivo del extracto de contrato que se expida para la ejecución de cada contrato. Se debe expedir un nuevo extracto por vencimiento del plazo inicial del mismo o por cambio del vehículo.
                                    `
                                ],
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                text: 'EJEMPLO:',
                                fontSize: 10,
                                bold: true
                            },
                            {
                                text: `Empresa habilitada por la Dirección Territorial Cundinamarca en el año 2012 con resolución de habilitación No. 0155, que expide el primer extracto del contrato en el año 2015, del contrato 255. El número del Formato Unico de Extracto del Contrato "FUEC" será : 425015512201502550001.
                                `,
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                fontSize: 7,
                                columns: [
                                    {
                                        text: `Nota:`,
                                        width: 25,
                                        bold: true
                                    },
                                    {
                                        text: `El vehiculo se encuentra en perfecto estado Mecánico y de aseo.`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                text: `Para verificar la autenticidad de esta planilla consultar la pagina web https://fuec.monitoringinnovation.com/fuec/${this.fuec_contract_number}.`,
                                fontSize: 7,
                                margin: [0, 10]
                            },
                            {
                                text: `INFORMACION POR RUTA ESTIMADA`,
                                fontSize: 7,
                                bold: true,
                                alignment: 'center',
                                margin: [0, 7]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Distancia recorrida estimada`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Duracion total estimada `,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Peajes`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                fontSize: 7,
                                alignment: 'center',
                                margin: [40, 5],
                                columns: [
                                    {
                                        text: `Consumo de combustible estimado`,
                                        width: '*'
                                    },
                                    {
                                        text: `Sin informacion`,
                                        width: '*'
                                    }
                                ]
                            },
                            {
                                text: `Resol. 315/2013 Art. PROTOCOLO DE ALISTAMIENTO DIARIO,Se deja constancia en este documento que participaron del proceso de alistamiento diaro el(los) conductor(es) mencionado(s) en este FUEC bajo la supervicion de la empresa a travéz deusos tecnológicos amparados por la ley 1450, art. 230 y del decreto ley 019 de 2012, art. 4`,
                                fontSize: 7
                            }
                        ]
                    ]
                }
            ]
        };

        pdfMake.tableLayouts = {
            exampleLayout: {
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return i === node.table.headerRows ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#8a8a8a';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                },
                paddingRight: function (i, node) {
                    return i === node.table.widths.length - 1 ? 0 : 8;
                }
            }
        };
        pdfMake.createPdf(docDefinition).open();
    }

    formatFuecSeries(): string {
        const separate = this.fuec_contract_number.split('');
        let num1 = '';
        for (var i = 0; i <= 2; i++) {
            num1 = `${num1}${separate[i]}`;
        }
        let num2 = '';
        for (var i = 3; i <= 6; i++) {
            num2 = `${num2}${separate[i]}`;
        }
        let num3 = '';
        for (var i = 7; i <= 8; i++) {
            num3 = `${num3}${separate[i]}`;
        }
        let num4 = '';
        for (var i = 9; i <= 12; i++) {
            num4 =
                this.current_year ===
                this.fuecForm.value.date_start.substring(0, 4)
                    ? this.current_year
                    : String(Number.parseInt(this.current_year) + 1);
        }
        let num5 = '';
        for (var i = 13; i <= 16; i++) {
            num5 = `${num5}${separate[i]}`;
        }
        let num6 = '';
        for (var i = 17; i <= 20; i++) {
            num6 = `${num6}${separate[i]}`;
        }
        return `${num1} ${num2} ${num3} ${num4} ${num5} ${num6}`;
    }

    // Individual services

    validateNumber(event: any): void {
        const input = event.target;
        input.value = input.value.replace(/[^0-9]/g, '');
    }

    onChangeObjContract() {
        switch (this.fuecForm.value.contract_object) {
            case '1':
                this.contract_object =
                    'Contrato para transporte de estudiantes';
                break;
            case '2':
                this.contract_object =
                    'Contrato para transporte de empleados y/o contratistas';
                break;
            case '3':
                this.contract_object = 'Contrato para transporte de turistas';
                break;
            case '4':
                this.contract_object = `Contrato para un grupo específico de usuarios (Transporte de
                    particulares)`;
                break;
            case '5':
                this.contract_object = `Contrato para transporte de usuarios del servicio de salud`;
                break;
            case '6':
                this.contract_object = `Contrato para transporte empresarial para prensa, cine y television`;
                break;
            case '7':
                this.contract_object = `Contrato para transporte empresarial`;
                break;
        }
    }

    formatDate(dateF) {
        const fecha = new Date(dateF);
        const year = fecha.getFullYear();
        const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const day = fecha.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    onChangeDateEnd() {
        const dateEndValue = this.fuecForm.get('date_end')?.value;
        if (!dateEndValue) {
            this.endDate = null;
            this.unitState.soatDate = null;
            this.unitState.tecnomecanicaDate = null;
            this.unitState.voDate = null;
            return;
        }
        this.endDate = new Date(dateEndValue);
        this.unitState.soatDate = this.unitState.vehicle.soat;
        this.unitState.tecnomecanicaDate = this.unitState.vehicle.tecnomecanica;
        this.unitState.voDate = this.unitState.vehicle.voperacion;
        this.cd.detectChanges();
    }

    zfill(number: string, width: number): string {
        let numberOutput = number.toString();
        let length = numberOutput.length;
        let zero = '0';

        if (width <= length) {
            return numberOutput;
        } else {
            return zero.repeat(width - length) + numberOutput;
        }
    }

    closeAllModals() {
        this.modalService.$modal.emit(false);
        this.viewState.show_view_contractor = false;
        this.viewState.show_view_responsible = false;
        this.viewState.show_view_route = false;
        this.viewState.show_view_units_external = false;
        this.syncUnits();
        document.body.classList.remove('no-scroll');
    }

    removeItemFromArr(arr: any, item: any) {
        var i = arr.indexOf(item);

        if (i !== -1) {
            arr.splice(i, 1);
        }
    }

    get agreement() {
        return this.fuecForm.value.agreement;
    }

    get agreement_desc() {
        return this.fuecForm.value.agreement_desc;
    }

    get date_start() {
        return this.fuecForm.value.date_start;
    }

    get date_end() {
        return this.fuecForm.value.date_end;
    }
}
